import type { rpc, tx, u, wallet } from "@cityofzion/neon-core";
import { throws } from "@/app/cuties/utils/utils";
import { NeoReader } from "@/cuties/blockchain/neo/NeoReader";
import type { NeoConfig } from "@/cuties/blockchain/neo/NeoConfig";
import type { ContractInvoke, NeoProvider } from "@/cuties/blockchain/neo/NeoProvider";
import type { PersonalMessageSignature, TransactionHash } from "@/components/LoginManager/TypeDefs";
import VueUtils from "@/cuties/VueUtils";
import NeoConfirmTransaction from "@/components/NeoConfirmTransaction.vue";
import BlockchainReaderService from "@/cuties/blockchain/BlockchainReaderService";
import { Blockchain } from "@/cuties/model/pet/BlockchainId";
import CutiesApiFaucet from "@/app/cuties/blockchain/CutiesApiFaucet";
import { CallFlags } from "@cityofzion/neon-core/lib/sc";

export class NeoPrivateKeyProvider implements NeoProvider {
    private constructor(
        private readonly config: NeoConfig,
        private readonly rpc: rpc.RPCClient,
        private readonly account: wallet.Account
    ) {}

    public static async create(config: NeoConfig, privateKey: string): Promise<NeoPrivateKeyProvider> {
        const neoRpc = await NeoReader.getNeo3(config);
        const { wallet } = await CutiesApiFaucet.getNeonCore();
        const account = new wallet.Account(privateKey);
        return new NeoPrivateKeyProvider(config, neoRpc, account);
    }

    public async getWalletAddress(): Promise<string> {
        return this.account.address;
    }

    public async signPersonalMessage(termsText: string): Promise<PersonalMessageSignature> {
        const { u, wallet } = await CutiesApiFaucet.getNeonCore();
        const hexed = u.str2hexstring(termsText);
        return wallet.sign(hexed, this.account.privateKey);
    }

    public checkReadyForTransaction(): Promise<void> {
        // since we have direct access to the private key, there
        // is no need to mess with extension configuration
        return Promise.resolve();
    }

    public async invoke(params: ContractInvoke): Promise<TransactionHash> {
        const { sc } = await CutiesApiFaucet.getNeonCore();
        const script = sc.createScript({
            scriptHash: params.scriptHash,
            operation: params.operation,
            args: params.args,
            callFlags: CallFlags.All,
        });

        const { tx } = await CutiesApiFaucet.getNeonCore();
        const signers = (params.signers ?? []).map(({ account, scopes }) => new tx.Signer({ account, scopes }));

        // eslint-disable-next-line prettier/prettier
        const [networkFee, systemFee, validUntilBlock] = await Promise.all([
            this.getNetworkFee(script, signers),
            this.getSystemFee(script, signers),
            this.rpc.getBlockCount().then((head) => head + 10),
        ]);

        const reader = await BlockchainReaderService.getNeoReader();

        await VueUtils.mountPopup(NeoConfirmTransaction, {
            from: this.account.address,
            networkFee: networkFee.toString(),
            systemFee: systemFee.toString(),
            reader: reader,
            blockchain: Blockchain.Neo3,
            value: params.value.toString(),
        });

        const transaction = new tx.Transaction({
            signers,
            script,
            networkFee,
            systemFee,
            validUntilBlock,
        });

        const signed = transaction.sign(this.account, this.config.networkMagic);
        return this.rpc.sendRawTransaction(signed);
    }

    private async getNetworkFee(script: string, signers: tx.Signer[], defaultNetworkFee = 0): Promise<u.BigInteger> {
        const { CONST, u, tx } = await CutiesApiFaucet.getNeonCore();
        const result = await this.rpc.invokeFunction(CONST.NATIVE_CONTRACT_HASH.PolicyContract, "getFeePerByte");

        if (result.state !== "HALT") {
            if (defaultNetworkFee === 0) {
                throw new Error("Unable to retrieve data to calculate network fee.");
            } else {
                console.warn("Unable to get information to calculate NEO network fee. Use default network fee.");
                return u.BigInteger.fromNumber(defaultNetworkFee);
            }
        }
        const feePerByte = u.BigInteger.fromNumber(result.stack[0].value as string);
        // Account for witness size
        const transactionByteSize = new tx.Transaction({ script, signers }).serialize().length / 2 + 109;
        // Hardcoded. Running a witness is always the same cost for the basic account.
        const witnessProcessingFee = u.BigInteger.fromNumber(1000390);
        const networkFeeEstimate = feePerByte.mul(transactionByteSize).add(witnessProcessingFee);
        if (defaultNetworkFee && networkFeeEstimate.compare(defaultNetworkFee) > 0) {
            return u.BigInteger.fromNumber(defaultNetworkFee);
        } else {
            return networkFeeEstimate;
        }
    }

    private async getSystemFee(script: string, signers: tx.Signer[], defaultSystemFee = 0): Promise<u.BigInteger> {
        const { u } = await CutiesApiFaucet.getNeonCore();
        const result = await this.rpc.invokeScript(u.HexString.fromHex(script), signers);
        if (result.state !== "HALT") {
            throws(`getSystemFee script errored out: ${result.exception}`);
        }

        const requiredSystemFee = u.BigInteger.fromNumber(result.gasconsumed);
        if (defaultSystemFee && requiredSystemFee.compare(defaultSystemFee) > 0) {
            return u.BigInteger.fromNumber(defaultSystemFee);
        } else {
            return requiredSystemFee;
        }
    }
}
